$(function() {
  const urlParams = new URLSearchParams(window.location.search);
  const isFocusParam = urlParams.get('focus') === 'true';
  let newSearchInput = $('#user_sign_up_search_attributes_brand_name');

  if (isFocusParam) {
    $('#user_sign_up_search_attributes_brand_name').focus();
    $('#query').focus();
    $('#query')[0].value = '';
  }
})
