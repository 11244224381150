$(function() {
  const $nav = $('nav#header');
  const $navbarToggler = $nav.find('button.navbar-toggler');
  const $closeButton = $nav.find('a.close-button');
  const $overlay = $nav.find('.overlay');
  const $footer = $('#footer');

  $navbarToggler.on('click', function() {
    $(this).css('display', 'none');
    $closeButton.show();
    $overlay.css("width", "100%");
    $footer.hide();
  });
  
  $closeButton.on('click', function() {
    $(this).css('display', 'none');
    $navbarToggler.show();
    $overlay.css("width", "0%");
    $footer.show();
  });
})

$(function() {
  const $videoModal = $('#videoModal');
  const $video = $videoModal.find('iframe');
  const $closeButton = $videoModal.find('.btn-close');

  function resetVideoSrc() {
    $video.attr('src', $video.attr('src'));
  }

  $videoModal.on('hidden.bs.modal', resetVideoSrc);

  $closeButton.on('click', resetVideoSrc);
});

$(document).on('scroll', function() {
  const nav = $('#header');
  const scroll = $(window).scrollTop();
  if (scroll > 0) {
    nav.addClass('navbar__shadow');
  } else {
    nav.removeClass('navbar__shadow');
  }
});


$(function() {
  const $buyMoreNav = $('.buy-more-nav');
  const userId = localStorage.getItem('user_id');

  $buyMoreNav.each(function() {
    $(this).on('click', function() {
      if (userId) {
        window.location.href = `/plan/new?user_id=${userId}`;
      } else {
        window.location.href = '/plan/new'
      }
    })
  })
})

$(function() {
  const $logoutButton = $('.logout-button');
  if ($logoutButton) {
    $logoutButton.on('click', function() {
      localStorage.removeItem('user_id');
    })
  }
})
