import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import gtag, { install } from 'ga-gtag';
install('G-85NLX2C38E');

gtag('config', 'G-85NLX2C38E');
gtag('config', 'AW-10793116392');

window.gtag = gtag;

import 'jquery'
import 'bootstrap'

import './navigation'
import './form-abandonment'
import './new-search'

var _ = require('lodash');
