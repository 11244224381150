$(document).on('turbo:load', function() {
  const signupForms = $('form');

  signupForms.on('submit', async function(e) {
    e.preventDefault;
    const recaptchaInput = $(this).find('input[name*="g-recaptcha-response-data"]').first();
    const matches = recaptchaInput.attr('name').match(/\[([^\]]+)\]/);

    if (matches) {
      let actionName = matches[1];
      actionName = actionName.split('_').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join('');

      const functionName = `executeRecaptchaFor${actionName}Async`;

      if (typeof window[functionName] === 'function') {
        
        try {
          const response = await window[functionName]();
          recaptchaInput.value = response;
         
          const formData = new FormData(this);

          // Send the AJAX request
          const fetchResponse = await fetch(this.action, {
            method: this.method,
            body: formData,
            headers: {
              'Accept': 'text/vnd.turbo-stream.html'
            }
          });
        } catch (error) {
          console.error(`Error calling ${functionName}:`, error);
        }
      } else {
        console.error(`Function ${functionName} is not defined.`);
      }
    }
  })
})
