import 'common/src/form-validation'
import '../../common/src/recaptcha'
import "@hotwired/turbo-rails"
import 'slick-slider/slick/slick'
Turbo.session.drive = false

function getResponsiveSettings(slidesToShow) {
  return {
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    customPaging: function(slider, i) {
      return `<p class="slick-counter">${i + 1} / ${Math.ceil(slider.slideCount / slidesToShow)}</p>`;
    }
  };
}

$(function() {   
  setTimeout(() => {  
    $("#testimonialSlickSlider").slick({
      slidesToShow: 1,
      dots: true,
      infinite: false,
      mobileFirst: true,
      
      responsive: [
        {
          breakpoint: 0,
          settings: getResponsiveSettings(1)
        },
        {
          breakpoint: 767,
          settings: getResponsiveSettings(2)
        },
        {
          breakpoint: 991,
          settings: getResponsiveSettings(4)
        },
        {
          breakpoint: 1199,
          settings: getResponsiveSettings(4)
        },
        {
          breakpoint: 1399,
          settings: getResponsiveSettings(4)
        },
        {
          breakpoint: 1600,
          settings: getResponsiveSettings(4)
        }
      ]
    });
  }, 100)

  setTimeout(() => {  
    $(".steps").slick({
      slidesToShow: 1,
      dots: true,
      infinite: false,
      mobileFirst: true,
      
      responsive: [
        {
          breakpoint: 0,
          settings: getResponsiveSettings(1)
        },
        {
          breakpoint: 767,
          settings: getResponsiveSettings(2)
        },
        {
          breakpoint: 991,
          settings: getResponsiveSettings(3)
        },
        {
          breakpoint: 1199,
          settings: getResponsiveSettings(3)
        },
        {
          breakpoint: 1399,
          settings: getResponsiveSettings(3)
        },
        {
          breakpoint: 1600,
          settings: getResponsiveSettings(3)
        }
      ]
    });
  }, 100)

  function updateHoverState(index, items) {
    items.each(function(i, item) {
      const shouldBeHovered = i === index;
      $(item).toggleClass('step-item__hovered', shouldBeHovered);
    })
  }

  setTimeout(() => {
    const $stepItems = $('.step-item');
    
    updateHoverState(0, $stepItems);
    
    $stepItems.on('mouseover', function() {
      const index = $stepItems.index(this);
      updateHoverState(index,  $stepItems);
    });
    $stepItems.on('touchend', function() {
      const index = $stepItems.index(this);
      updateHoverState(index,  $stepItems);
    });
  }, 100);
  
  $(window).on('resize', () => {
    setTimeout(() => {
      const $stepItems = $('.step-item');
      
      $stepItems.on('mouseover', function() {
        const index = $stepItems.index(this);
        updateHoverState(index,  $stepItems);
      });
      $stepItems.on('touchend', function() {
        const index = $stepItems.index(this);
        updateHoverState(index,  $stepItems);
      });
    }, 100);
  })
});

$(function() {
  var urlParams = new URLSearchParams(window.location.search);

  if(urlParams.has('resubmit')) {
    $('#check-brand-form').submit();
  }

  if ($(window).width() > 575) {
    $('#pressCarousel .hide-unless-xs').removeClass('carousel-item').addClass('d-none');
  }
})

$(document).on("turbo:submit-end ", function () {
  setTimeout(() => {
    $("#check-mail-modal").modal("show");
    const $verifyEmailModal = document.getElementById('check-mail-modal');  
    if (!$verifyEmailModal) return;
  
    
    const $user_id = $verifyEmailModal.getAttribute('data-user-id');
    if (!$user_id) return;
  
    localStorage.setItem('user_id', $user_id);
  }, 1000);
  setTimeout(() => {
    $("#end-trial-modal").modal("show");
  }, 1000);
  setTimeout(() => {
    $("#thank-request-modal").modal("show");
  }, 1000);
});

$(function() {
  const plan = ['startUp', 'agency', 'consultant'];

  plan.forEach((type) => {
    $(`#${type}Button`).on('click', () => {
      plan.forEach((item) => {
        if (item == type) {
          $(`#${item}Button`).addClass('active');
          $(`#${item}Content`).addClass('active');
        } else {
          $(`#${item}Button`).removeClass('active');
          $(`#${item}Content`).removeClass('active');
        }
      })
    })
    $(`#${type}Content`).on('click', () => {
      plan.forEach((item) => {
        if (item == type) {
          $(`#${item}Button`).addClass('active');
          $(`#${item}Content`).addClass('active');
        } else {
          $(`#${item}Button`).removeClass('active');
          $(`#${item}Content`).removeClass('active');
        }
      })
    })
  })
});

$(function() {
  $('.questions-item').each(function() {
    $(this).on('click', function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    })
  })
})

$('a[href="#faq"]').on('click', function(event) {
  // Prevent default anchor click behavior
  event.preventDefault();
  // Scroll to #faq section
  $('html, body').animate({
    scrollTop: $('#faq').offset().top
  });
});

$(function() {
  $(document).on('scroll', function() {
    const scrollPosition = window.scrollY;
    const background = document.querySelector('.scroll-background');
    const opacity = 1 - scrollPosition / window.innerHeight;

    if (background) {
      background.style.opacity = opacity;
    }
  });
})

$(function() {
  const planContents = $('.plan-content').children();
  const titles = $(planContents[0]).children();
  
  const updateTitleHeight = () => {
    const activePlan = planContents.filter((_, item) => $(item).hasClass('active'))[0];
    const descriptions = $(activePlan).children();

    titles.each((index, item) => {
      $(item).css('box-sizing', 'content-box');
      $(item).css('height', `${$(descriptions[index]).height()}px`);
    });
  };

  updateTitleHeight();

  $('.plan-content').on('click', function() {
    updateTitleHeight();
  });
});

