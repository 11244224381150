// Fetch all the forms we want to apply custom Bootstrap validation styles to
var forms = document.querySelectorAll('.needs-validation')

// Loop over them and prevent submission
Array.prototype.slice.call(forms)
  .forEach(function (form) {
    form.addEventListener('submit', function (event) {
      let submitButton = form.querySelector('button[type="submit"]');
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        submitButton.disabled = true;
      }
      
      form.classList.add('was-validated')
    }, false)
  })
